<template>
  <div>
    <h4>Berechnung</h4>
    <h6>Haftpflichtberechnung</h6>
    <div class="container mb-4">
      <div class="row">
        <div class="col">
          Grundtarif:
        </div>
        <div
          class="col"
        >
          {{ result.haftpflichtCalculation.grundtarif }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          kdFactor Faktor Kundendaten:
        </div>
        <div class="col">
          {{ result.haftpflichtCalculation.kdFactor }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          fzFactor Faktor Fahrzeug:
        </div>
        <div class="col">
          {{ result.haftpflichtCalculation.fzFactor }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          otherFactor:
        </div>
        <div class="col">
          {{ result.haftpflichtCalculation.otherFactor }}
        </div>
      </div>
      <div class="row border border-dark">
        <div class="col">
          Ergebnis:
        </div>
        <div class="col">
          {{ haftpflichtResult }} (grundtarif * kdFactor * fzFactor * otherFactor)
        </div>
      </div>
    </div>
    <h6>Kaskoberechnung</h6>
    <div class="container mb-4">
      <div class="row">
        <div class="col">
          Grundtarif (Kasko):
        </div>
        <div
          class="col"
        >
          {{ result.kaskoCalculation.grundtarif }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          kdFactor Faktor Kundendaten:
        </div>
        <div class="col">
          {{ result.kaskoCalculation.kdFactor }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          fzFactor Faktor Fahrzeug:
        </div>
        <div class="col">
          {{ result.kaskoCalculation.fzFactor }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          otherFactor:
        </div>
        <div class="col">
          {{ result.kaskoCalculation.otherFactor }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          additionalKaskoFactor:
        </div>
        <div class="col">
          {{ result.kaskoCalculation.additionalKaskoFactor }}
        </div>
      </div>
      <div class="row border border-dark">
        <div class="col">
          Ergebnis:
        </div>
        <div class="col">
          {{ kaskoResult }} (grundtarif * kdFactor * fzFactor * otherFactor * additionalKaskoFactor)
        </div>
      </div>
    </div>
    <h6>Zusatzfaktoren</h6>
    <div class="container mb-4">
      <div
        v-if="result.schutzpacketFahrerschutzNetto"
        class="row"
      >
        <div class="col">
          schutzpacketFahrerschutzNetto
        </div>
        <div class="col">
          {{ result.schutzpacketFahrerschutzNetto }}
        </div>
      </div>
      <div
        v-if="result.zusatzpaketPlusNetto"
        class="row"
      >
        <div class="col">
          zusatzpaketPlusNetto
        </div>
        <div class="col">
          {{ result.zusatzpaketPlusNetto }}
        </div>
      </div>
      <div
        v-if="result.zusatzpacketElektroNetto"
        class="row"
      >
        <div class="col">
          zusatzpacketElektroNetto
        </div>
        <div class="col">
          {{ result.zusatzpacketElektroNetto }}
        </div>
      </div>
      <div
        v-if="result.zusatzpaketFreiesFahrenNetto"
        class="row"
      >
        <div class="col">
          zusatzpaketFreiesFahrenNetto
        </div>
        <div class="col">
          {{ result.zusatzpaketFreiesFahrenNetto }}
        </div>
      </div>
      <div
        v-if="result.weltweiterVersicherungsschutzNetto"
        class="row"
      >
        <div class="col">
          weltweiterVersicherungsschutzNetto
        </div>
        <div class="col">
          {{ result.weltweiterVersicherungsschutzNetto }}
        </div>
      </div>
    </div>
    <h6>Endergebnis (Haftpflicht + Kasko + Zusatzfaktoren)</h6>
    <div class="container mb-4">
      <div class="row border border-dark">
        <div class="col-6">
          Jahresprämie Netto:
        </div>
        <div class="col-6">
          {{ result.netto }}
        </div>
        <div class="col-6">
          Jahresprämie Brutto:
        </div>
        <div class="col-6">
          {{ result.brutto }}
        </div>
      </div>
      <div class="row border border-dark">
        <div class="col">
          Prämie nach Zahlart:
        </div>
        <div class="col">
          {{ result.rateBrutto }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    result: {
      type: Object,
      default: null
    }
  },
  computed: {
    haftpflichtResult () {
      if (!this.result) return null
      return this.result.haftpflichtCalculation.grundtarif * this.result.haftpflichtCalculation.kdFactor * this.result.haftpflichtCalculation.fzFactor * this.result.haftpflichtCalculation.otherFactor
    },
    kaskoResult () {
      if (!this.result) return null
      return this.result.kaskoCalculation.grundtarif * this.result.kaskoCalculation.kdFactor * this.result.kaskoCalculation.fzFactor * this.result.kaskoCalculation.otherFactor * this.result.kaskoCalculation.additionalKaskoFactor
    }
  }
}
</script>
