<template>
  <div>
    <OrderOverviewModal
      v-if="showOrderOverviewModal"
      :order="order"
      :customer="customer"
      :result="result"
      :pending="pending"
      :button-text="buttonText"
      :create-document="createDocument"
      :error-message="errorMessage"
      @save="save"
      @close="showOrderOverviewModal = false"
    />
    <HSSOrderForms
      v-if="order"
      heading="Tarifrechner"
      :order="order"
      :client="client"
      :customer="customer"
      :loading="loading"
      :show-validation-error="showValidationError"
      :show-product-validation-border="showProductValidationBorder"
      :validate-angebot="validateAngebot"
      @setCustomer="setCustomer"
      @isHardknockout="isHardknockout=$event"
      @isUeberpruefung="isUeberpruefung=$event"
      @result="result = $event"
      @fillMockData="order = $event"
      @validateData="validateData($event)"
    >
      <template #earlyOrderEnd>
        <div class="col-12 mb-3">
          <div
            v-if="showAnfrageButton"
            class="row mb-3"
          >
            <div class="col-6">
              <Card>
                <template #body>
                  <h4>
                    Anfrage einreichen
                  </h4>
                  <p>
                    Gern überprüfen wir die Versicherbarkeit als Liebhaberfahrzeug. Sie können eine Anfrage an dieser Stelle einreichen oder weitere Daten eingeben und die Anfrage dann einreichen.
                  </p>
                  <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="disableAnfrageButton"
                    @click="validateDataAnfrage(hsOrderTypes.ANFRAGE.api)"
                  >
                    <i
                      v-if="pending"
                      class="fas fa-circle-notch fa-spin"
                    />
                    Anfrage einreichen
                  </button>
                </template>
              </Card>
            </div>
          </div>
          <div
            v-if="showAngebotButton"
            class="row"
          >
            <div class="col-6">
              <Card>
                <template #body>
                  <h4>
                    Angebot speichern
                  </h4>
                  <p>
                    Sie können an dieser Stelle bereits den aktuellen Angebotsstand speichern. Alternativ können Sie auch die Weiteren Daten erfassen und das Angebot dann speichern oder einen Antrag einreichen.
                  </p>
                  <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="disableAngebotButton"
                    @click="validateDataAngebot(hsOrderTypes.ANGEBOT.api)"
                  >
                    <i
                      v-if="pending"
                      class="fas fa-circle-notch fa-spin"
                    />
                    Angebot speichern
                  </button>
                </template>
              </Card>
            </div>
          </div>
        </div>
      </template>
      <template #buttons>
        <p class="text-danger w-100 text-right">
          {{ errorMessage }}
        </p>
        <button
          v-if="showAnfrageButton"
          type="button"
          class="btn btn-primary mr-2"
          :disabled="disableAnfrageButton"
          @click="validateDataAnfrage(hsOrderTypes.ANFRAGE.api)"
        >
          Anfrage senden
          <i
            v-if="pending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
        <button
          v-if="showAngebotButton"
          type="button"
          class="btn btn-primary mr-2"
          :disabled="disableAngebotButton"
          @click="validateDataAngebot(hsOrderTypes.ANGEBOT.api)"
        >
          Angebot speichern
          <i
            v-if="pending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
        <button
          v-if="($can('createPolicyAntrag') || $can('createCreatePolicyAntragIgnoringAnfrageReasons'))"
          type="button"
          class="btn btn-success text-white"
          :disabled="antragDisabled || pending || customer.neuabschlussGesperrt"
          @click="validateData(hsOrderTypes.ANTRAG.api)"
        >
          Antrag einreichen
          <i
            v-if="pending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
      </template>
    </HSSOrderForms>
    <HSSOrderVisualCalculation
      v-if="result && $can('seePolicyCalculation')"
      :result="result"
    />
  </div>
</template>

<script>
import feathers from '@/api'
import segmente from '@/resources/enums/segmente.js'
import hsOrderTypes from '@/resources/enums/hsOrderTypes'
import HSSOrderForms from '@/components/Forms/Order/HSSOrderForms.vue'
import HSSOrderVisualCalculation from '@/components/HSSOrderVisualCalculation.vue'
import origins from '@/resources/enums/origins'
import OrderOverviewModal from '@/components/Modals/OrderOverviewModal.vue'
import Card from '@/components/Card.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    HSSOrderForms,
    HSSOrderVisualCalculation,
    OrderOverviewModal,
    Card
  },
  data () {
    return {
      hsOrderTypes,
      loading: false,
      error: null,
      columnLayout: true,
      showOrderModal: false,
      order: null,
      result: null,
      saveErrorMessage: null,
      showFormInvalidHint: null,
      pending: false,
      customer: {},
      prices: null,
      missingFields: [],
      showValidationError: false,
      isHardknockout: false,
      isUeberpruefung: false,
      client: null,
      showOrderOverviewModal: false,
      validateAngebot: false,
      errorMessage: null
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    showProductValidationBorder () {
      if (!this.order) return false
      return !this.order.product && this.showValidationError
    },
    antragDisabled () {
      // Calculation sagt ablehnung
      if (this.order.ablehnung) return true
      // Caculation nicht erfolgreich
      if (this.result && !this.result.success) return true
      // Calculation sagt Anfragepflichtig und der Nutzer darf keine Anfragepflichtigen Anträge stellen
      // später  && !this.$can('createCreatePolicyAntragIgnoringAnfrageReasons')
      if (this.result && this.result.anfrage) return true
      // Validierung sagt Anfragepflichtig und der Nutzer darf keine Anfragepflichtigen Anträge stellen
      // später && !this.$can('createCreatePolicyAntragIgnoringAnfrageReasons')
      if (this.isUeberpruefung) return true
      // Calculation sagt Hardknockout
      if (this.isHardknockout) return true
      return false
    },
    showAnfrageButton () {
      return (this.isUeberpruefung && !this.isHardknockout && this.$can('createPolicyAnfrage'))
    },
    disableAnfrageButton () {
      return this.pending || this.customer.neuabschlussGesperrt
    },
    showAngebotButton () {
      return (this.$can('createPolicyAngebot') && !this.isUeberpruefung && !this.isHardknockout)
    },
    disableAngebotButton () {
      return this.order.ablehnung || (this.result && !this.result.success) || (this.result && this.result.anfrage) || this.isUeberpruefung || this.isHardknockout || this.pending || this.customer.neuabschlussGesperrt
    }

  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async validateDataAngebot (orderType) {
      this.validateAngebot = true
      await this.$nextTick()
      this.validateData(orderType)
      this.validateAngebot = false
    },
    async validateDataAnfrage (orderType) {
      this.validateAngebot = true
      await this.$nextTick()
      this.validateData(orderType)
      this.validateAngebot = false
    },
    async validateData (orderType) {
      this.showValidationError = !await this.$validator.validateAll() || !this.order.product
      if (this.showValidationError) return
      this.order.hsOrderType = orderType
      this.order.origin = origins.VERWALTUNG.api

      if (this.order.carData.fahrzeugAuswahl === 'insuranceNewCar') {
        this.order.versicherungsbeginn = undefined
        this.keineSchaeden = undefined
        this.order.carData.kennzeichen = undefined
        this.order.carData.fahrzeugID = undefined
      }

      for (const entry of Object.values(segmente)) {
        if (
          entry.von <= this.order.fzErstzulassung &&
          entry.bis >= this.order.fzErstzulassung
        ) {
          this.order.segment = entry.api
          break
        }
      }
      switch (this.order.hsOrderType) {
      case hsOrderTypes.ANFRAGE.api:
        this.buttonText = 'Anfrage senden'
        this.createDocument = false
        break
      case hsOrderTypes.ANGEBOT.api:
        this.buttonText = 'Angebot speichern'
        this.createDocument = false
        break
      case hsOrderTypes.ANTRAG.api:
        this.buttonText = 'Antrag einreichen'
        this.createDocument = true
        break
      default: this.buttonText = 'Vorgang abschließen!'
        this.createDocument = true
        break
      }
      this.showOrderOverviewModal = true
    },
    async save () {
      if (this.pending) return
      this.pending = true
      try {
        if (!this.order.customerId) {
          const newCustomer = await feathers.service('customer').create(this.customer)
          this.$set(this.order, 'customerId', newCustomer._id)
          this.$set(this.order, 'customer', newCustomer)
        }
        this.order.aenderungGueltigAb = new Date()
        this.order = await feathers.service('hs-order').create(this.order)
        this.$router.push({
          name: 'HSSOrderDetail',
          params: { orderId: this.order._id }
        })
      } catch (e) {
        this.errorMessage = e.message
        console.error(e)
      } finally {
        this.pending = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
        this.order = new this.$FeathersVuex.api.HsOrder()
        this.$set(this.order, 'origin', origins)
        if (this.$route.params.customer) {
          this.$set(this.order, 'customerId', this.$route.params.customer._id)
          this.setCustomer(this.$route.params.customer)
        }
        if (this.$route.params.orderId) {
          const orderOriginal = await feathers.service('hs-order').get(this.$route.params.orderId)
          const fieldsToCopy = [
            'abstellplatzPlz',
            'abwKontoinhaber',
            'bankname',
            'bic',
            'blz',
            'carData',
            'carNotFound',
            'classicCar',
            'classicCarId',
            'customerId',
            'customerKind',
            'fahrerkreis',
            'geburtsdatumFahrerkreisMax',
            'geburtsdatumFahrerkreisMin',
            'haftpflichtDiscount',
            'iban',
            'isAbwKontoinhaber',
            'kaskoDiscount',
            'mrDeckung',
            'product',
            'rabatt',
            'versicherungsbeginn',
            'vorschaeden',
            'wertnachweis',
            'wertnachweisDatum',
            'wertnachweisFileIds',
            'wertnachweisFiles',
            'wertnachweisWirdNachgereicht',
            'wiederherstellungswert',
            'wiederherstellungswertVersichern',
            'wkz',
            'zahlart',
            'zahlweise',
            'agentNumber',
            'userId',
            'untervermittlerNummer'
          ]
          for (const field of fieldsToCopy) {
            this.$set(this.order, field, orderOriginal[field])
          }
          this.$set(this.order.productPackages, orderOriginal.product, orderOriginal.productPackages[orderOriginal.product])
          this.setCustomer(orderOriginal.customer)
        }
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    setCustomer (newCustomer) {
      this.$set(this, 'customer', newCustomer)
    }
  }
}
</script>

<style scoped lang="scss">
.order-info {
  position: sticky;
  top: 70px;
}
</style>
